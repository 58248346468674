// .btn {
//   display: inline-block;
//   font-weight: 400;
//   color: #212529;
//   text-align: center;
//   vertical-align: middle;
//   background-color: transparent;
//   border: 1px solid transparent;
//   padding: 0.375rem 0.75rem;
//   font-size: 14px;
//   border-radius: 3px;
//   transition: .5s all ease-in;
// }


// .btn-padding-same .dx-button-content {
//   padding: 0;
//   font-weight: 600;
// }

// 
//   background: #4780FF !important;
//   color: #fff !important;
//   padding: 10px 12px !important;
//   text-transform: capitalize !important;
//   transition: .5s all ease-in;
// }

// .btn-block {
//   width: 100%;
// }

// .btn-round {
//   border-radius: 90px !important;
// }
.btn-icon {
  border: 1px solid #4780FF !important;
  color: #4780FF !important;
}
.btn {
  transition: .5s all ease;
  white-space: nowrap;
  color: $white !important;
  padding: 5px 14px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
  font-size: 11px !important;
}

.btn-blue {
  border: 1px solid #4780FF !important;
  background: #4780FF !important;
  @extend .btn;
  border-radius: 5px;
  font-size: 11px !important;

  &:hover {
    border: 1px solid $vermilion !important;
    background: $vermilion !important;
    border-radius: 5px;
  }
}

.btn-black {
  background: $black !important;
  border: 1px solid $black !important;
  @extend .btn;
  &.Mui-disabled {
    opacity: .5;
  }
}

.btn-red {
  background: $vermilion !important;
  border: 1px solid $vermilion !important;
  @extend .btn;
}

.toggle-btn.Mui-selected {
  background-color: $vermilion !important;
}

.toggle-btn {
  background: #239500 !important;
  color: #fff !important;
  padding: 3px 14px !important;
  background: #fff;
}

.btn-outline-danger {
  color: $vermilion !important;
  border-color: $vermilion !important;
}

.Mui-disabled.btn-blue {
  opacity: .5;
}

.bg-light-primary {
  background: #0162e81a;
  color: #4780FF;
}

// .btn-blue-2.Mui-disabled {
//   opacity: .5;
// }

// .btn-grey-2 {
//   background: rgb(158, 141, 141) !important;
//   transition: .5s all ease;
//   color: white !important;
// }

// .btn-blue-2:hover,
// .btn-blue:hover {
//   background: $vermilion !important;
//   border-color: $vermilion !important;
// }

// .btn-outlin-2:hover {
//   background: $vermilion !important;
//   border-color: $vermilion !important;
//   color: #fff !important;
// }

// button {
//   text-transform: capitalize !important;
// }

// .btn-blue-2,
// .btn-green,
// .btn-red {
//   color: #fff !important;
//   padding: 6px 16px !important;
//   text-transform: capitalize !important;
//   font-size: 14px !important;
// }

// .btn-outlin-2 {
//   padding: 6px 20px !important;
//   font-size: 14px !important;
// }

// .btn-green {
//   background: #367A00 !important;
// }

// .btn-red {
//   background: $vermilion !important;
// }

// .btn-favorite {
//   color: #666666 !important;
//   padding: 6px 8px !important;
//   border-color: #666666;
// }

// .btn-favorite.Mui-selected {
//   background-color: $vermilion !important;
//   color: #fff !important;
// }


.text-bg-primary {
  background: rgba(1, 98, 232, 0.1) !important;
  color: #4780FF !important;
}
.text-bg-success {
  background: rgb(132 232 1 / 10%) !important;
  color: #699f00 !important;
}
.text-bg-danger {
  background: rgba(232, 1, 1, 0.1) !important;
  color: #e13a3a !important;
}
.text-bg-info {
  background: rgb(1 232 200 / 10%) !important;
  color: #118978 !important;
}
.text-bg-warning {
  background-color: #ffe49c !important;
  color: #664e08 !important;
}
.Mui-selected {
  border-color: #d42027 !important;
  background: #d42027 !important;
  color: #fff !important;
}
.comment-button{
  position: relative;
    right: 20px;
    top: -65px;
}

@media only screen and (max-width:1024px) {
  .comment-button{
    position: relative;
      right: 20px;
      top: -57px;
  }
}
