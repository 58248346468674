.swal2-container.swal2-center.swal2-backdrop-show {
    z-index: 9999 !important;
  }
  
  .green {
    color: green;
  }


  .swal2-container .swal2-popup.swal2-modal .swal2-icon.swal2-warning {
    color: #dd3333;
    border-color: #dd3333;
    font-size: 12px;
  }
  
  .swal2-container .swal2-popup.swal2-modal .swal2-default-outline.swal2-confirm {
    background-color: #4780ff !important;
    font-size: 14px !important;
  }
  
  .swal2-container .swal2-popup.swal2-modal {
    background: #fff;
    padding: 30px;
  }
  
  .swal2-container .swal2-popup.swal2-modal .swal2-cancel {
    background-color: #d42027 !important;
    font-size: 14px !important;
  }

/* 
  #success {
    background: green;
  }
  #error {
    background: red;
  }
  #warning {
    background: coral;
  }
  #info {
    background: cornflowerblue;
  }
  #question {
    background: grey;
  } */
  