        @keyframes wave2 {
            25% {
                transform-origin: 0 100%;
            }

            50% {
                transform: scale(1.8, 1.3);
            }

            75% {
                transform-origin: 100% 100%;
            }

            100% {
                transform: scale(1, 1.3);
            }
        }

        @keyframes wave4 {
            25% {
                transform: scaleY(0.9);
            }

            75% {
                transform: scaleY(1.1) scaleX(1.02);
            }
        }

        @keyframes star {
            0% {
                transform: scale(0.3);
                opacity: 0;
            }

            50% {
                transform: scale(0.8);
                opacity: 1;
            }

            75% {
                transform: scale(1) rotate(45deg);
                opacity: 1;
            }

            100% {
                transform: scale(1.01) rotate(-5deg);
                opacity: 1;
            }
        }

        @keyframes star2 {
            0% {
                transform: scale(0.3);
            }

            50% {
                transform: scale(0.8);
            }

            75% {
                transform: scale(1);
            }

            100% {
                transform: scale(1.01);
            }
        }

        @-webkit-keyframes spin {
            0% {
                transform: translateX(-10px) scale(0.9);
            }

            100% {
                transform: translateX(30px) scale(1.3) translateY(10px);
            }
        }

        @keyframes spin {
            0% {
                transform: translateX(-10px) scale(0.9);
            }

            100% {
                transform: translateX(30px) scale(1.3) translateY(10px);
            }
        }

        @-webkit-keyframes spin1 {
            0% {
                transform: scale(0.9);
            }

            100% {
                transform: scale(1.5);
            }
        }

        @keyframes spin1 {
            0% {
                transform: scale(0.9);
            }

            100% {
                transform: scale(1.5);
            }
        }

        @-webkit-keyframes spin2 {
            0% {
                transform: translateY(0);
            }

            100% {
                transform: translateY(40px);
            }
        }

        @keyframes spin2 {
            0% {
                transform: translateY(0);
            }

            100% {
                transform: translateY(40px);
            }
        }

        @-webkit-keyframes pulse1 {
            0% {
                opacity: 1;
                transform: translate(-50%, -50%) scale(0.5);
            }

            100% {
                opacity: 0;
                transform: translate(-50%, -50%) scale(4.5);
                border-radius: 50%;
            }
        }

        @keyframes pulse1 {
            0% {
                opacity: 1;
                transform: translate(-50%, -50%) scale(0.5);
            }

            100% {
                opacity: 0;
                transform: translate(-50%, -50%) scale(4.5);
                border-radius: 50%;
            }
        }

        @keyframes customUp {
            0% {
                transform: translateY(8px)
            }

            100% {
                transform: translateY(0)
            }
        }

        @keyframes clipInDown {
            0% {
                clip-path: polygon(-10% -10%, -10% -10%, 110% -10%, 110% -10%);
            }

            100% {
                clip-path: polygon(-10% 110%, -10% -10%, 110% -10%, 110% 110%);
            }
        }

        .clipInDown {
            animation-name: clipInDown;
        }

        /*================ preloader css ====================*/
        #preloader {
            background: #fff;
            height: 80vh;
            bottom: 0;
            left: 0;
            /* position: fixed; */
            /* position: absolute; */
            right: 0;
            top: 0;
            /* z-index: 999; */
            z-index: 8;
            display: flex !important;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 90px 0;

            .loading {
                display: flex;
            }

            .ctn-preloader {
                padding-left: 15px;
                padding-right: 15px;
            }

            .spinner {
                animation: spinner 3s infinite linear;
                border-radius: 50%;
                border: 3px solid #f1f6f8;
                border-left-color: #D41B20;
                border-top-color: #D41B20;
                margin: 0 auto 0em auto;
                position: absolute;
                left: -30px;
                right: -30px;
                bottom: -30px;
                top: -30px;

                &:before {
                    content: "";
                    width: 20px;
                    height: 20px;
                    border: 6px solid #fff;
                    box-shadow: 0 0 20px 0 rgba(4, 46, 56, 0.2);
                    background: #D41B20;
                    position: absolute;
                    right: 31px;
                    top: 41px;
                    border-radius: 50%;

                    @media (max-width: 576px) {
                        top: 18px;
                    }
                }
            }

            .round_spinner {
                border-width: 1px;
                border-color: rgb(238, 243, 244);
                border-style: solid;
                border-radius: 50%;
                background-color: rgb(253, 253, 253);
                box-shadow: 0 0 100px 0 rgba(4, 46, 56, 0.14);
                width: 120px;
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                margin: 40px auto 80px;

                img {
                    width: 26px;
                    margin-bottom: 10px;
                }

                @media (max-width: 576px) {
                    width: 115px;
                    height: 115px;
                }

                h4 {
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 0;
                    color: #1d2746;

                    span {
                        font-weight: 700;
                    }
                }
            }
        }

        #preloader .head {
            color: #1d2746;
            display: block;
            font-size: 26px;
            font-weight: 700;
            letter-spacing: 5.2px;
            text-transform: uppercase;
            text-align: center;
            margin: 5% 0 1% 0;
            padding: 0;
        }

        #preloader p {
            color: #6b707f;
            display: block;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            border: none;
            margin: 0;
            padding: 0;
        }

        @keyframes spinner {
            to {
                transform: rotateZ(360deg);
            }
        }