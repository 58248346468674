*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
    display: block;
}

body {
    font-size: 14px;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input {
    -webkit-appearance: none;
    border-radius: 0;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

h1,
.h1 {
    font-size: 2.5rem;
}

h2,
.h2 {
    font-size: 2rem;
}

h3,
.h3 {
    font-size: 1.75rem;
}

h4,
.h4 {
    font-size: 1.5rem;
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

/* width */
::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $vermilion;
    border-radius: 90px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}