
/* timeline status */
.activity-timeline {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
  }
  
  .activity-timeline ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .activity-timeline ul li {
    display: flex;
    position: relative;
  }
  
  .activity-timeline ul li .datetime {
    display: inline-block;
    width: 120px;
    padding-top: 3px;
    padding-right: 12px;
  }
  
  .activity-timeline ul li .datetime span {
    display: block;
    font-size: 13px;
    color: #7b7b7b;
  }
  
  .activity-timeline ul li .line-dotted {
    display: inline-flex;
    width: 20px;
    justify-content: center;
  }
  
  .activity-timeline ul li .line-dotted .line-time {
    width: 2px;
    background: #e6e6e6;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
  }
  
  .activity-timeline ul li .line-dotted .circle-time {
    width: 8px;
    height: 8px;
    background: #D41B20;
    border-radius: 50%;
    position: relative;
    top: 4px;
    z-index: 1;
  }
  
  .activity-timeline ul li .line-dotted .circle-border {
    width: 16px;
    height: 16px;
    border: 1px solid #D41B20;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    background: #fff;
  }
  
  .activity-timeline ul li .timeline-details {
    display: inline-block;
    width: 100%;
    margin-left: 26px;
    border: 1px solid #D41B20;
    border-radius: 4px;
    padding: 15px 15px 10px 15px;
    margin-bottom: 15px;
    position: relative;
  }
  
  .activity-timeline ul li .timeline-details:after {
    border-left: 10px solid red;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    content: '';
  }
  
  .activity-timeline ul li .timeline-details:after {
    border-left: 10px solid transparent;
    border-right: 10px solid rgb(212 27 32 / 75%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    content: '';
    position: absolute;
    left: -20px;
    top: 0;
  }
  
  .activity-timeline ul li .timeline-details .content-time {
    /* display: inline-block; */
  }
  
  .activity-timeline ul li .timeline-details .content-time .user-name {}
  
  .activity-timeline ul li .timeline-details .content-time h5 {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .activity-timeline ul li .timeline-details .content-time p {
    margin-bottom: 3px;
    font-size: 14px;
  }
  
  .activity-timeline.activity-timeline-2 {
    max-height: calc(100vh - 360px);
    overflow: auto;
  }
  
  /* timeline status end */