.custom-modal {

    .MuiDialog-paper {
        width: 100%;
    }

    .modal-head {
        // padding: 15px 15px 15px 25px;
        border-bottom: 2px solid #f1f1f1;

        button {
            color: $black;

            &:hover {
                background: transparent;
            }
        }
    }

    .MuiDialog-container .MuiDialog-paperWidthSm {
        width: 100%;
        // max-width: 850px;
        border-radius: 10px;
        /* padding: 10px 20px 10px; */
    }

}


.modal-request-document {
    .white-box {
        padding: 0;
        box-shadow: none !important;
    }

    .col-md-8 {
        width: 100%;
        padding: 0;
    }
}

.modal-lg .MuiDialog-paper.MuiDialog-paperScrollPaper {
    width: 100%;
}